import prism from "./prism";

export default {
  background: "#fff",
  sideBackground: "#fafafa",
  accent: "#6166DC",
  articleText: "#4a4a4a",
  strongText: "#363636",
  border: "#dedede",
  guideBackground: "#f2f7fc",

  gradient: "linear-gradient(180deg, rgba(217, 219, 224, 0) 0%, #D9DBE0 100%)",
  grey: "#73737D",
  hover: "rgba(0, 0, 0, 0.07)",
  primary: "#7f00ff",
  prism,
  secondary: "#00ff99",
  track: "rgba(8, 8, 11, 0.3)",
  progress: "#000",
  card: "#fff",
  error: "#EE565B",
  success: "#46B17B",
  errorBackground: "rgba(238, 86, 91, 0.1)",
  horizontalRule: "rgba(8, 8, 11, 0.15)",
  inputBackground: "rgba(0, 0, 0, 0.05)",
  link: "",
  modes: {
    dark: {
      background: "#1c1c21",
      sideBackground: "#1f1f1f",
      accent: "#6166DC",
      articleText: "#bfbfbf",
      strongText: "#bfbfbf",
      border: "#3b3b3b",
      guideBackground: "#0d2135",

      grey: "#73737D",
      primary: "#fff",
      secondary: "#fff",
      hover: "rgba(255, 255, 255, 0.07)",
      gradient:
        "linear-gradient(180deg, #111216 0%, rgba(66, 81, 98, 0.36) 100%)",
      track: "rgba(255, 255, 255, 0.3)",
      progress: "#fff",
      card: "#1D2128",
      error: "#EE565B",
      success: "#46B17B",
      errorBackground: "rgba(238, 86, 91, 0.1)",
      horizontalRule: "rgba(255, 255, 255, 0.15)",
      inputBackground: "rgba(255, 255, 255, 0.07)",
      link: "rgb(191, 191, 191)",
    },
  },
};
