exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-mdx": () => import("./../../../src/pages/donate.mdx" /* webpackChunkName: "component---src-pages-donate-mdx" */),
  "component---src-pages-recommended-index-js": () => import("./../../../src/pages/recommended/index.js" /* webpackChunkName: "component---src-pages-recommended-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-2017-year-in-review-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/2017-year-in-review/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-2017-year-in-review-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-2020-pluralsight-free-april-review-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/2020-pluralsight-free-april-review/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-2020-pluralsight-free-april-review-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-2021-year-in-review-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/2021-year-in-review/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-2021-year-in-review-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-angular-add-custom-font-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/angular-add-custom-font/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-angular-add-custom-font-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-asp-net-core-handling-custom-header-on-request-response-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/asp-net-core-handling-custom-header-on-request-response/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-asp-net-core-handling-custom-header-on-request-response-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-asp-net-core-starting-the-web-server-is-taking-longer-than-expected-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/asp-net-core-starting-the-web-server-is-taking-longer-than-expected/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-asp-net-core-starting-the-web-server-is-taking-longer-than-expected-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-aspnet-core-oauth-2-0-client-access-token-management-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/aspnet-core-oauth-2-0-client-access-token-management/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-aspnet-core-oauth-2-0-client-access-token-management-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-aspnet-core-return-500-internal-server-error-or-any-other-status-code-from-api-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/aspnet-core-return-500-internal-server-error-or-any-other-status-code-from-api/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-aspnet-core-return-500-internal-server-error-or-any-other-status-code-from-api-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-aspnet-core-using-multiple-authentication-schemes-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/aspnet-core-using-multiple-authentication-schemes/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-aspnet-core-using-multiple-authentication-schemes-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-aspnet-web-forms-manually-trigger-client-side-validation-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/aspnet-web-forms-manually-trigger-client-side-validation/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-aspnet-web-forms-manually-trigger-client-side-validation-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-azure-application-gateway-list-of-failed-requests-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/azure-application-gateway-list-of-failed-requests/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-azure-application-gateway-list-of-failed-requests-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-azure-cognitive-search-geographypoint-to-geojson-in-dotnet-5-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/azure-cognitive-search-geographypoint-to-geojson-in-dotnet-5/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-azure-cognitive-search-geographypoint-to-geojson-in-dotnet-5-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-azure-configuring-end-to-end-ssl-for-iis-website-by-using-application-gateway-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/azure-configuring-end-to-end-ssl-for-iis-website-by-using-application-gateway/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-azure-configuring-end-to-end-ssl-for-iis-website-by-using-application-gateway-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-azure-devops-build-could-not-locate-the-assembly-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/azure-devops-build-could-not-locate-the-assembly/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-azure-devops-build-could-not-locate-the-assembly-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-azure-devops-ci-cd-pipeline-involving-helm-3-acr-aks-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/azure-devops-ci-cd-pipeline-involving-helm-3-acr-aks/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-azure-devops-ci-cd-pipeline-involving-helm-3-acr-aks-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-azure-devops-gatsbyjs-build-pipeline-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/azure-devops-gatsbyjs-build-pipeline/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-azure-devops-gatsbyjs-build-pipeline-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-azure-logic-apps-get-application-insights-azure-monitor-logs-query-result-on-microsoft-teams-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/azure-logic-apps-get-application-insights-azure-monitor-logs-query-result-on-microsoft-teams/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-azure-logic-apps-get-application-insights-azure-monitor-logs-query-result-on-microsoft-teams-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-azure-web-app-missing-mime-types-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/azure-web-app-missing-mime-types/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-azure-web-app-missing-mime-types-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-azure-web-app-web-deploy-to-a-sub-folder-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/azure-web-app-web-deploy-to-a-sub-folder/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-azure-web-app-web-deploy-to-a-sub-folder-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-best-font-for-visual-studio-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/best-font-for-visual-studio/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-best-font-for-visual-studio-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-best-free-form-provider-for-static-websites-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/best-free-form-provider-for-static-websites/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-best-free-form-provider-for-static-websites-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-best-laptop-for-programming-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/best-laptop-for-programming/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-best-laptop-for-programming-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-best-umbraco-packages-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/best-umbraco-packages/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-best-umbraco-packages-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-best-visual-studio-code-extensions-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/best-visual-studio-code-extensions/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-best-visual-studio-code-extensions-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-best-visual-studio-extensions-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/best-visual-studio-extensions/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-best-visual-studio-extensions-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-c-sharp-convert-datetime-to-long-and-vice-versa-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/c-sharp-convert-datetime-to-long-and-vice-versa/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-c-sharp-convert-datetime-to-long-and-vice-versa-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-c-sharp-get-last-n-characters-from-a-string-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/c-sharp-get-last-n-characters-from-a-string/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-c-sharp-get-last-n-characters-from-a-string-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-c-sharp-get-web-config-connection-string-in-a-class-library-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/c-sharp-get-web-config-connection-string-in-a-class-library/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-c-sharp-get-web-config-connection-string-in-a-class-library-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-check-if-string-is-arabic-csharp-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/check-if-string-is-arabic-csharp/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-check-if-string-is-arabic-csharp-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-cloudflare-and-aspnet-boilerplate-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/cloudflare-and-aspnet-boilerplate/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-cloudflare-and-aspnet-boilerplate-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-cost-optimization-auto-shutdown-and-startup-of-tagged-vms-with-azure-automation-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/cost-optimization-auto-shutdown-and-startup-of-tagged-vms-with-azure-automation/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-cost-optimization-auto-shutdown-and-startup-of-tagged-vms-with-azure-automation-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-create-sql-server-database-from-a-script-in-docker-compose-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/create-sql-server-database-from-a-script-in-docker-compose/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-create-sql-server-database-from-a-script-in-docker-compose-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-dapper-execute-multiple-stored-procedures-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/dapper-execute-multiple-stored-procedures/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-dapper-execute-multiple-stored-procedures-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-determine-total-number-of-open-active-connections-in-ms-sql-server-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/determine-total-number-of-open-active-connections-in-ms-sql-server/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-determine-total-number-of-open-active-connections-in-ms-sql-server-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-docker-cookbook-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/docker-cookbook/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-docker-cookbook-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-docker-sql-error-on-aspnet-core-alpine-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/docker-sql-error-on-aspnet-core-alpine/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-docker-sql-error-on-aspnet-core-alpine-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-dot-net-interview-questions-and-answers-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/dot-net-interview-questions-and-answers/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-dot-net-interview-questions-and-answers-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-dotnet-core-api-gateway-ocelot-logging-http-requests-response-including-headers-body-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/dotnet-core-api-gateway-ocelot-logging-http-requests-response-including-headers-body/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-dotnet-core-api-gateway-ocelot-logging-http-requests-response-including-headers-body-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-dotnet-core-nuget-packages-ci-cd-using-github-actions-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/dotnet-core-nuget-packages-ci-cd-using-github-actions/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-dotnet-core-nuget-packages-ci-cd-using-github-actions-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-download-file-using-wcf-rest-service-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/download-file-using-wcf-rest-service/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-download-file-using-wcf-rest-service-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-enable-click-jacking-protection-umbraco-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/enable-click-jacking-protection-umbraco/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-enable-click-jacking-protection-umbraco-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-event-tracking-with-google-analytics-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/event-tracking-with-google-analytics/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-event-tracking-with-google-analytics-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-fetch-row-count-for-all-tables-in-a-sql-server-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/fetch-row-count-for-all-tables-in-a-sql-server/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-fetch-row-count-for-all-tables-in-a-sql-server-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-filtering-paging-and-sorting-in-sql-server-2008-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/filtering-paging-and-sorting-in-sql-server-2008/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-filtering-paging-and-sorting-in-sql-server-2008-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-fix-web-deploy-could-not-verify-the-server-certificate-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/fix-web-deploy-could-not-verify-the-server-certificate/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-fix-web-deploy-could-not-verify-the-server-certificate-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-flutter-cookbook-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/flutter-cookbook/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-flutter-cookbook-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-gatsby-cookbook-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/gatsby-cookbook/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-gatsby-cookbook-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-gatsby-see-all-generated-pages-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/gatsby-see-all-generated-pages/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-gatsby-see-all-generated-pages-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-gatsbyjs-add-google-adsense-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/gatsbyjs-add-google-adsense/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-gatsbyjs-add-google-adsense-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-get-set-hidden-field-value-using-jquery-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/get-set-hidden-field-value-using-jquery/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-get-set-hidden-field-value-using-jquery-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-git-branch-not-showing-in-visual-studio-team-explorer-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/git-branch-not-showing-in-visual-studio-team-explorer/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-git-branch-not-showing-in-visual-studio-team-explorer-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-github-vs-azure-devops-for-code-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/github-vs-azure-devops-for-code/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-github-vs-azure-devops-for-code-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-gitlab-clone-a-repository-when-2-fa-enabled-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/gitlab-clone-a-repository-when-2fa-enabled/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-gitlab-clone-a-repository-when-2-fa-enabled-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-hello-world-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/hello-world/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-hello-world-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-how-we-use-jira-automation-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/how-we-use-jira-automation/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-how-we-use-jira-automation-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-hunting-security-bugs-in-an-old-web-application-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/hunting-security-bugs-in-an-old-web-application/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-hunting-security-bugs-in-an-old-web-application-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-iis-disable-cors-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/iis-disable-cors/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-iis-disable-cors-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-iis-options-requests-returns-404-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/iis-options-requests-returns-404/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-iis-options-requests-returns-404-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-inserting-rewrite-rule-in-release-config-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/inserting-rewrite-rule-in-release-config/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-inserting-rewrite-rule-in-release-config-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-ip-security-configure-ip-address-restrictions-in-web-config-on-iis-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/ip-security-configure-ip-address-restrictions-in-web-config-on-iis/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-ip-security-configure-ip-address-restrictions-in-web-config-on-iis-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-javascript-determine-if-user-is-on-mobile-device-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/javascript-determine-if-user-is-on-mobile-device/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-javascript-determine-if-user-is-on-mobile-device-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-markdown-link-within-document-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/markdown-link-within-document/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-markdown-link-within-document-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-microsoft-clarity-setting-up-with-gatsbyjs-and-first-impressions-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/microsoft-clarity-setting-up-with-gatsbyjs-and-first-impressions/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-microsoft-clarity-setting-up-with-gatsbyjs-and-first-impressions-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-microsoft-orleans-run-multiple-silos-from-a-net-core-console-app-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/microsoft-orleans-run-multiple-silos-from-a-net-core-console-app/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-microsoft-orleans-run-multiple-silos-from-a-net-core-console-app-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-microsoft-sql-server-guy-trying-oracle-databse-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/microsoft-sql-server-guy-trying-oracle-databse/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-microsoft-sql-server-guy-trying-oracle-databse-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-ndepend-the-best-in-class-static-code-analyzer-for-dotnet-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/ndepend-the-best-in-class-static-code-analyzer-for-dotnet/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-ndepend-the-best-in-class-static-code-analyzer-for-dotnet-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-netstandard-20-project-docfx-msbuild-error-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/netstandard20-project-docfx-msbuild-error/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-netstandard-20-project-docfx-msbuild-error-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-pad-a-number-with-leading-zeros-in-sql-to-make-uniform-char-length-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/pad-a-number-with-leading-zeros-in-sql-to-make-uniform-char-length/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-pad-a-number-with-leading-zeros-in-sql-to-make-uniform-char-length-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-project-management-organize-issues-using-labels-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/project-management-organize-issues-using-labels/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-project-management-organize-issues-using-labels-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-react-native-build-apk-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/react-native-build-apk/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-react-native-build-apk-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-redirect-http-to-https-using-webconfig-in-iis-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/redirect-http-to-https-using-webconfig-in-iis/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-redirect-http-to-https-using-webconfig-in-iis-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-redirect-https-requests-to-http-using-iis-rewrite-rule-in-webconfig-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/redirect-https-requests-to-http-using-iis-rewrite-rule-in-webconfig/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-redirect-https-requests-to-http-using-iis-rewrite-rule-in-webconfig-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-redirect-non-www-urls-to-www-urls-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/redirect-non-www-urls-to-www-urls/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-redirect-non-www-urls-to-www-urls-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-redirect-www-to-non-www-using-webconfig-in-iis-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/redirect-www-to-non-www-using-webconfig-in-iis/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-redirect-www-to-non-www-using-webconfig-in-iis-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-remove-specific-class-from-all-elements-jquery-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/remove-specific-class-from-all-elements-jquery/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-remove-specific-class-from-all-elements-jquery-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-serilog-console-sink-themes-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/serilog-console-sink-themes/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-serilog-console-sink-themes-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-set-selected-option-by-value-via-jquery-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/set-selected-option-by-value-via-jquery/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-set-selected-option-by-value-via-jquery-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-sitefinity-caching-issue-for-pages-with-no-caching-profile-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/sitefinity-caching-issue-for-pages-with-no-caching-profile/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-sitefinity-caching-issue-for-pages-with-no-caching-profile-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-sitefinity-development-problems-solutions-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/sitefinity-development-problems-solutions/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-sitefinity-development-problems-solutions-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-sitefinity-read-localized-resource-labels-in-mvc-widget-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/sitefinity-read-localized-resource-labels-in-mvc-widget/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-sitefinity-read-localized-resource-labels-in-mvc-widget-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-sitefinity-rookie-guide-get-users-in-a-custom-role-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/sitefinity-rookie-guide-get-users-in-a-custom-role/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-sitefinity-rookie-guide-get-users-in-a-custom-role-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-sql-server-delete-duplicate-rows-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/sql-server-delete-duplicate-rows/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-sql-server-delete-duplicate-rows-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-style-guide-sql-server-auditing-column-names-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/style-guide-sql-server-auditing-column-names/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-style-guide-sql-server-auditing-column-names-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-the-best-extensions-for-visual-studio-2010-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/the-best-extensions-for-visual-studio-2010/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-the-best-extensions-for-visual-studio-2010-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-umbraco-get-current-page-in-partial-view-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/umbraco-get-current-page-in-partial-view/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-umbraco-get-current-page-in-partial-view-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-using-automapper-in-a-net-core-class-library-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/using-automapper-in-a-net-core-class-library/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-using-automapper-in-a-net-core-class-library-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-visual-studio-keeps-crashing-first-aid-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/visual-studio-keeps-crashing-first-aid/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-visual-studio-keeps-crashing-first-aid-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-vue-js-list-rendering-limit-items-in-v-for-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/vue-js-list-rendering-limit-items-in-v-for/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-vue-js-list-rendering-limit-items-in-v-for-index-mdx" */),
  "component---src-templates-article-template-js-content-file-path-content-blog-xamarin-development-problems-and-solutions-index-mdx": () => import("./../../../src/templates/article-template.js?__contentFilePath=/vercel/path0/content/blog/xamarin-development-problems-and-solutions/index.mdx" /* webpackChunkName: "component---src-templates-article-template-js-content-file-path-content-blog-xamarin-development-problems-and-solutions-index-mdx" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-snippet-template-js-content-file-path-content-snippets-c-sharp-mask-string-mdx": () => import("./../../../src/templates/snippet-template.js?__contentFilePath=/vercel/path0/content/snippets/c-sharp/mask-string.mdx" /* webpackChunkName: "component---src-templates-snippet-template-js-content-file-path-content-snippets-c-sharp-mask-string-mdx" */),
  "component---src-templates-snippet-template-js-content-file-path-content-snippets-c-sharp-truncate-string-without-word-break-mdx": () => import("./../../../src/templates/snippet-template.js?__contentFilePath=/vercel/path0/content/snippets/c-sharp/truncate-string-without-word-break.mdx" /* webpackChunkName: "component---src-templates-snippet-template-js-content-file-path-content-snippets-c-sharp-truncate-string-without-word-break-mdx" */),
  "component---src-templates-snippet-template-js-content-file-path-content-snippets-git-delete-all-local-remote-git-tags-mdx": () => import("./../../../src/templates/snippet-template.js?__contentFilePath=/vercel/path0/content/snippets/git/delete-all-local-remote-git-tags.mdx" /* webpackChunkName: "component---src-templates-snippet-template-js-content-file-path-content-snippets-git-delete-all-local-remote-git-tags-mdx" */),
  "component---src-templates-snippet-template-js-content-file-path-content-snippets-javascript-decode-jwt-token-mdx": () => import("./../../../src/templates/snippet-template.js?__contentFilePath=/vercel/path0/content/snippets/javascript/decode-jwt-token.mdx" /* webpackChunkName: "component---src-templates-snippet-template-js-content-file-path-content-snippets-javascript-decode-jwt-token-mdx" */),
  "component---src-templates-snippet-template-js-content-file-path-content-snippets-javascript-domain-name-from-url-mdx": () => import("./../../../src/templates/snippet-template.js?__contentFilePath=/vercel/path0/content/snippets/javascript/domain-name-from-url.mdx" /* webpackChunkName: "component---src-templates-snippet-template-js-content-file-path-content-snippets-javascript-domain-name-from-url-mdx" */),
  "component---src-templates-snippet-template-js-content-file-path-content-snippets-linux-dns-lookup-mdx": () => import("./../../../src/templates/snippet-template.js?__contentFilePath=/vercel/path0/content/snippets/linux/dns-lookup.mdx" /* webpackChunkName: "component---src-templates-snippet-template-js-content-file-path-content-snippets-linux-dns-lookup-mdx" */),
  "component---src-templates-snippet-template-js-content-file-path-content-snippets-powershell-output-environment-variable-mdx": () => import("./../../../src/templates/snippet-template.js?__contentFilePath=/vercel/path0/content/snippets/powershell/output-environment-variable.mdx" /* webpackChunkName: "component---src-templates-snippet-template-js-content-file-path-content-snippets-powershell-output-environment-variable-mdx" */),
  "component---src-templates-snippet-template-js-content-file-path-content-snippets-sql-server-create-new-database-level-user-mdx": () => import("./../../../src/templates/snippet-template.js?__contentFilePath=/vercel/path0/content/snippets/sql-server/create-new-database-level-user.mdx" /* webpackChunkName: "component---src-templates-snippet-template-js-content-file-path-content-snippets-sql-server-create-new-database-level-user-mdx" */),
  "component---src-templates-snippet-template-js-content-file-path-content-snippets-sql-server-utc-to-uae-time-mdx": () => import("./../../../src/templates/snippet-template.js?__contentFilePath=/vercel/path0/content/snippets/sql-server/utc-to-uae-time.mdx" /* webpackChunkName: "component---src-templates-snippet-template-js-content-file-path-content-snippets-sql-server-utc-to-uae-time-mdx" */),
  "component---src-templates-snippets-category-wise-template-js": () => import("./../../../src/templates/snippets-category-wise-template.js" /* webpackChunkName: "component---src-templates-snippets-category-wise-template-js" */),
  "component---src-templates-snippets-template-js": () => import("./../../../src/templates/snippets-template.js" /* webpackChunkName: "component---src-templates-snippets-template-js" */),
  "component---src-templates-stories-template-js": () => import("./../../../src/templates/stories-template.js" /* webpackChunkName: "component---src-templates-stories-template-js" */),
  "component---src-templates-topic-stories-template-js": () => import("./../../../src/templates/topic-stories-template.js" /* webpackChunkName: "component---src-templates-topic-stories-template-js" */),
  "component---src-templates-topic-template-js": () => import("./../../../src/templates/topic-template.js" /* webpackChunkName: "component---src-templates-topic-template-js" */),
  "component---src-templates-topic-tools-template-js": () => import("./../../../src/templates/topic-tools-template.js" /* webpackChunkName: "component---src-templates-topic-tools-template-js" */),
  "component---src-templates-topic-videos-template-js": () => import("./../../../src/templates/topic-videos-template.js" /* webpackChunkName: "component---src-templates-topic-videos-template-js" */),
  "component---src-templates-topics-template-js": () => import("./../../../src/templates/topics-template.js" /* webpackChunkName: "component---src-templates-topics-template-js" */),
  "component---src-templates-videos-page-js": () => import("./../../../src/templates/videos-page.js" /* webpackChunkName: "component---src-templates-videos-page-js" */)
}

